import React from 'react';

/*---Material---*/
import { Box, CircularProgress } from '@mui/material';

/*---CVT---*/
import { CvtColorSchema, DeviceSizes } from '@conventic-web/internal.global';
import { Alert } from '@conventic-web/internal.basic.alert';

/*---Component---*/
import { LinkComponent } from '../../../components/linkComponent';

/*---Global---*/
import { getJobOfferFromPersonio } from '../../../global/functions';
import { SystemObj } from '../../../global/sharedObjects';
import SYSTEM_STRINGS from '../../../utility/data/system/systemStrings.yaml';



const CardKarriereHome = () => {
  const { isLoading, error, data } = getJobOfferFromPersonio();
	const styles = {
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [`@media screen and (max-width: ${DeviceSizes.tablet})`]: {
        flexDirection: 'column',
      },
    },
    karriereTitle: {
      color: CvtColorSchema.white,
      fontWeight: 600,
      padding: ' 0 1em 1em 0',
      [`@media screen and (max-width: ${DeviceSizes.tablet})`]: {
        padding: ' 0 1em 0 0',
      },
    },
    jobOffContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      padding: '1em 0',
    },
  } as const;

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center">
        <Alert
          severity={SystemObj.SEVERITY.ERROR as any}
          text={SYSTEM_STRINGS.ERROR_MESSAGES.LOADING}
          variant="filled"
        />
      </Box>
    );
  }

  if (data) {
    return (
      <Box component="div" sx={styles.root}>
        {(() => {
          const returnArr = [];
          let count = 0;
          for (const position of data['workzag-jobs']['position']) {
            if (3 < count) {
              break;
            }
            returnArr.push(
              <Box component="div" sx={styles.jobOffContainer} key={count}>
                {position.name[0] && (
                  <Box component="div" sx={styles.karriereTitle}>
                    {position.name[0]}
                  </Box>
                )}
                <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.KARRIERE}>
                  {SYSTEM_STRINGS.GENERAL.WORDS.MORE}
                </LinkComponent>
              </Box>
            );
            count++;
          }
          return returnArr;
        })()}
      </Box>
    );
  } else {
    return (
      <Box component="div" sx={styles.root}>
        <Box component="div" sx={styles.jobOffContainer}>
          <Box component="div" sx={styles.karriereTitle}>
            {SYSTEM_STRINGS.COMPONENTS.KARRIERE.NO_JOB_OPP}
          </Box>
          <LinkComponent linkTo={SYSTEM_STRINGS.LINKS.INTERN.KARRIERE}>
            {SYSTEM_STRINGS.GENERAL.WORDS.MORE}
          </LinkComponent>
        </Box>
      </Box>
    );
  }
};

/**
 * @description Returns a preview for the home-page .
 * @see /index.js
 */

export default CardKarriereHome;
