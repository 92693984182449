import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

/*---CVT---*/
import { CvtColorSchema } from '@conventic-web/internal.global';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';

import { DiagonalObj, PaddingObj } from '../global/sharedObjects';

type CarouselProps = { data: any };

const Carousel: React.FC<CarouselProps> = ({ data }: CarouselProps) => {
  const items = (data: any) => {
    return data.indexCarousel.map((item: any, index: number) => {
      return (
        <React.Fragment key={'pageblockTop-' + index}>
          <BlockTop
            headline={item.title}
            subheadline={item.subtitle}
            diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
            bgImage={
              !item.image.childImageSharp && item.image.extension === 'svg'
                ? item.image.publicURL
                : null
            }
            zIndex={9}
            padding={PaddingObj.PaddingStarter}
            color={CvtColorSchema.white}
          />
        </React.Fragment>
      );
    });
  };

  return (
    <AliceCarousel
      autoPlay={true}
      animationDuration={7500}
      autoPlayInterval={3000}
      disableDotsControls
      disableButtonsControls
      infinite
      items={items(data)}
      mouseTracking={false}
      touchTracking={false}
    />
  );
};

export default Carousel;
